<template>
  <!-- 队伍人员 -->
  <div class="main-contain">
    <div class="contain">
      <el-table :data="tableData" v-loading="loading" style="width: 100%">
        <el-table-column label="姓名" prop="name"></el-table-column>
        <el-table-column label="职称" prop="job_name"></el-table-column>
        <el-table-column label="所属院系" prop="college_name"></el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { getLabManager } from "@/api/openroombook"
export default {
  name: "Courseintroduction",
  components: {},
  data () {
    return {
      loading: false,
      tableData: [],
      lab_room_id: "",
    }
  },
  methods: {
    initdata (obj) {
      let that = this
      that.lab_room_id = obj.id
      that.getData()
    },
    getData () {
      this.loading = true
      getLabManager({ lab_room_id: this.lab_room_id }).then(res => {
        if (res.data && res.code === 0) {
          this.tableData = res.data.data
          this.loading = false
        }
      }).catch((error) => {
        this.loading = false
      })
    }
  },
};
</script>
<style lang="scss" scoped>
.main-contain {
  position: relative;
  margin-top: 20px;
  height: 100%;
  .contain {
    position: absolute;
    width: 100%;
    height: 100%;
    .table-head-op {
      display: flex;
      justify-content: center;
      align-items: center;
      .text {
        user-select: none;
        white-space: nowrap;
      }
      .elselect {
        margin-left: 10px;
      }
    }
    .state {
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #288add;
      user-select: none;
      cursor: pointer;
    }
  }
}
</style>
